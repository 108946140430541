import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';

const Header = () => (
  <header>
    <h1>Jenn &amp; Paul</h1>
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/schedule">Schedule</Link>
      </li>
      <li>
        <Link to="/rsvp">RSVP</Link>
      </li>
      <li>
        <Link to="/registry">Registry</Link>
      </li>
    </ul>
  </header>
);

export default Header;
