import React, { Fragment } from 'react';
import { renderToString } from 'react-dom/server';
import ApolloClient from 'apollo-boost';
import './RSVP.scss';
import gql from 'graphql-tag';
import * as emailjs from 'emailjs-com';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import EmailMessage from './EmailMessage';

const API_TOKEN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZXJzaW9uIjoxLCJ0b2tlbklkIjoiYTE4N2JkYTktN2U0ZC00MzhmLWE4M2ItNzBjOTA5OTgzNmUyIn0.yuNJpw1OtoOsUCQ9qhfpCPaM_Dezf07vr9vBcuGWczI';
const API_ENDPOINT = 'https://api-uswest.graphcms.com/v1/cjxjpwho30aea01d1db77j1zj/master';
const client = new ApolloClient({
  uri: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${API_TOKEN}`,
  },
});

const CREATE_RSVP = gql`
  mutation CreateRsvp(
    $names: String!
    $attending: Boolean!
    $emailAddress: String!
    $joinForCeremony: Boolean
    $joinForRehearsal: Boolean
    $joinForDinner: String
    $joinForDrinks: Boolean
    $furtherNotice: Boolean
    $partySize: Int!
    $message: String
  ) {
    createRsvp(
      data: {
        names: $names
        attending: $attending
        emailAddress: $emailAddress
        joinForCeremony: $joinForCeremony
        joinForRehearsal: $joinForRehearsal
        joinForDinner: $joinForDinner
        joinForDrinks: $joinForDrinks
        furtherNotice: $furtherNotice
        partySize: $partySize
        message: $message
      }
    ) {
      id
      names
      attending
      emailAddress
      joinForCeremony
      joinForRehearsal
      joinForDinner
      joinForDrinks
      furtherNotice
      partySize
      message
    }
  }
`;

class Form extends React.Component {
  state = {
      currentStep: 1,
      names: '',
      attending: false,
      emailAddress: '',
      joinForCeremony: false,
      joinForRehearsal: false,
      joinForDinner: '',
      joinForDrinks: false,
      furtherNotice: false,
      partySize: 1,
      message: '',
      done: false,
      namesError: null,
      emailAddressError: null,
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;

    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
        case 'number':
          value = parseInt(target.value);
          break;
      default:
        value = target.value;
    }
    // HAXXXX
    if (value === "true") value = true;
    if (value === "false") value = false;
    if (name === 'partySize') value = parseInt(value);

    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (e) => {
    console.log(this.state);
    e.preventDefault();

    client
      .mutate({
        mutation: CREATE_RSVP,
        variables: this.state,
      })
      .then(result => {
        console.log(result);

        // reset state to fill out again
          this.setState((prevState) => ({
            done: true,
            currentStep: 1,
            names: '',
            attending: false,
            attendingTitle: false,
            emailAddress: '',
            joinForCeremony: false,
            joinForRehearsal: false,
            joinForDinner: '',
            joinForDrinks: false,
            furtherNotice: false,
            partySize: 1,
            message: '',
            namesError: null,
            emailAddressError: null,
          }));
      });
  }

  _validateStep = () => {
    const { currentStep, names, emailAddress } = this.state;
    if (currentStep === 1) {
      if (isEmpty(emailAddress)) {
        this.setState({
          emailAddressError: 'Please enter an email address.',
        })
        return false;
      } else if (!isEmail(emailAddress)) {
        this.setState({
          emailAddressError: 'Please enter a valid email address.',
        })
        return false;
      }
    }

    this.setState({
      emailAddressError: null,
      namesError: null,
    })

    return true;
  }

  // _next = () => {
  //   if (!this._validateStep()) return;

  //   let { currentStep, attending } = this.state;
  //   if (!attending && currentStep === 2) {
  //     currentStep = 4;
  //   } else {
  //     currentStep = currentStep + 1;
  //   }
  //   this.setState({
  //     currentStep: currentStep,
  //     done: false,
  //   })
  // }

  // _prev = () => {
  //   let { currentStep, attending } = this.state;
  //   if (currentStep === 4 && !attending) {
  //     currentStep = 2;
  //   } else {
  //     currentStep = currentStep - 1;
  //   }
  //   this.setState({
  //     currentStep: currentStep
  //   })
  // }

  render() {
    return (
      <Fragment>
        <div className="view">
          <section className='transamerica bg-fixed'>
              <h2 className='image-heading'>RSVP</h2>
          </section>
        </div>
        <section className="Form section">
          <div className="container">
            <div className="columns">

                <form
                  action="#"
                  onSubmit={this.handleSubmit}
                  className="form column is-three-fifths is-offset-one-fifth"
                >
                  { this.state.done 
                    ? <h1 className="title">YAY! See you there!</h1>
                    : <Fragment>
                      <h2 className="title">Let's hang out!</h2>

                      <div className="content"> 
                        <p>
                          You can also contact us at
                          {' '}
                          <a href="mailto:us@jennandpaul.com">us@jennandpaul.com</a>.
                        </p>
                      </div>

                      <Step1 handleInputChange={this.handleInputChange} {...this.state} />
                      {/* <Step2 handleInputChange={this.handleInputChange} {...this.state} />
                      <Step3 handleInputChange={this.handleInputChange} {...this.state} />
                      <Step4 handleInputChange={this.handleInputChange} {...this.state} /> */}

                      <div className="field">
                        <button 
                          className="button is-medium is-dark is-pulled-right"
                          onClick={() => this.handleSubmit}>
                          Send
                        </button>
                      </div>
                    </Fragment>
                  }

                </form>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Form;
