import React, { Fragment } from 'react';
import './registry.scss';

class Registry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item_name: '',
      amount: 50.0,
      items: [
        ['Honeymoon Flight', '600.00'],
        ['Honeymoon Hotel', '250.00'],
        ['Honeymoon Excursion', '150.00'],
        ['House Fund', '100.00'],
        ['House Fund', '50.00'],
        ['Wedding Costs', '100.00'],
        ['Wedding Costs', '50.00'],
        ['Breville Smart Toaster Oven', '150.00'],
        ['Breville Small Espresso Machine', '460.00'],
        ['Fancy Oil Diffuser', '120.00'],
        ['Kitchen Aid Hand Mixer', '55.00'],
        ['Other', '50.00'],
      ],
    };

  }

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;

    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = parseInt(target.value);
        break;
      default:
        value = target.value;
    }

    this.setState({
      [name]: value,
    });
  }

  handleChooseItem = item => {
    return event => {
      this.setState({
        item_name: item[0],
        amount: item[1],
      });
    };
  }

  renderItem(item) {
    return (
      <li key={Math.random()}>
        <button className="button is-text" onClick={this.handleChooseItem(item)}>
          {item[0]}: ${item[1]}
        </button>
      </li>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="view">
          <section className='us_beach bg-fixed'>
              <h2 className='image-heading'>Registry</h2>
          </section>
        </div>
        <section className="Form section">
          <div className="container">
            <div className="columns">
              <div className="column is-three-fifths is-offset-one-fifth">
                <p>
                  At a few people's request, we've decided to add a registry of sorts for those who
                  would like to send a gift. We're reasonably grown adults who have most things we need,
                  but we won't stand in the way if anyone feels the need to shower us with presents.
                </p>
                <p>
                  Since there's not much in the way of "things" that we need and/or want, you're also more
                  than welcome to donate towards our wedding, honeymoon, or house fund! 
                </p>
                <p>Just click on an item to choose:</p>
                <ul className="registry-list">{this.state.items.map(i => this.renderItem(i))}</ul>

                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                  className="form"
                >
                  <input type="hidden" name="cmd" value="_donations" />
                  <input type="hidden" name="business" value="thrashr888@gmail.com" />
                  <input type="hidden" name="charset" value="utf-8" />
                  <input
                    type="hidden"
                    name="return"
                    value="http://wedding.jennandpaul.com/registry/return"
                  />
                  <input type="hidden" name="currency_code" value="USD" />

                  <div className="field">
                    <label className="label">Item</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        name="item_name"
                        placeholder="ex. My Little Pony BluRay"
                        required
                        value={this.state.item_name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Amount</label>
                    <div className="field-body">
                      <div className="field">
                        <div className="field has-addons">
                          <div className="control">
                            <div className="button is-static">$</div>
                          </div>
                          <div className="control">
                            <input
                              type="text"
                              className="input"
                              name="amount"
                              placeholder="ex. 50"
                              required
                              value={this.state.amount}
                              onChange={this.handleInputChange}
                              style={{ boxSizing: 'content-box' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  <input
                    type="submit"
                    className="button is-medium is-dark"
                    border="0"
                    name="submit"
                    value="Donate (via PayPal)"
                    alt="Donate with PayPal button"
                  />
                  <p className='help'>(And, of course, thank you!!)</p>
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Registry;
