import React from 'react';
import './schedule.scss';

const Schedule = () => (
    <div className='view'>
        <section className='pac-heights bg-fixed'>
            <h2 className='image-heading'>Schedule</h2>
        </section>
        <section className='schedule-details'>
            <div className='schedule-section'>
                <div className='event-title'>
                    <span className='event-name'>Rehearsal</span>
                    <span className='event-date'>Friday, September 13th</span>
                    <span className='event-time'>5:00pm</span>
                </div>
                <div className='event-details'>
                    <address className='event-address'>
                        <strong>Swedenborgian Church of San Francisco</strong><br />
                        2107 Lyon St<br />
                        San Francisco, CA 94115
                    </address>
                    <div className='event-note'>
                        (Note: Rehearsal is invite only, but feel free to join us after for dinner and/or drinks!)
                    </div>
                    <a className='directions' href='https://goo.gl/maps/Us9Se2LFcKUN9rSZA' target='_blank' rel='noopener noreferrer'>Directions</a>
                </div>
            </div>
            <div className='schedule-section'>
                <div className='event-title'>
                    <span className='event-name'>Rehearsal Dinner & Drinks</span>
                    <span className='event-date'>Friday, September 13th</span>
                    <span className='event-time'>7:00pm</span>
                </div>
                <div className='event-details'>
                    <address className='event-address'>
                        <strong>Thirsty Bear Brewing Company</strong><br />
                        661 Howard St<br />
                        San Francisco, CA 94105
                    </address>
                    <div className='event-note'>
                        If you'd like to make <a href='https://www.yelp.com/reservations/thirsty-bear-brewing-company-san-francisco-5?covers=2&date=2019-09-13&source=widget&time=1900&utm_source=widget' target='_blank' rel='noopener noreferrer'>reservations</a> for
                        dinner, you can request a table near ours. Just ask
                        to be sat near the Jenn & Paul rehearsal party! Otherwise, they have tons of bar space and high tops
                        for drink and snacks.
                    </div>
                    <a className='directions' href='https://goo.gl/maps/oR8W4BZA1Cvukd8w9' target='_blank' rel='noopener noreferrer'>Directions</a>
                </div>
            </div>
            <div className='schedule-section'>
                <div className='event-title'>
                    <span className='event-name'>Ceremony</span>
                    <span className='event-date'>Saturday, September 14th</span>
                    <span className='event-time'>2:00pm</span>
                </div>
                <div className='event-details'>
                    <address className='event-address'>
                        <strong>Swedenborgian Church of San Francisco</strong><br />
                        2107 Lyon St<br />
                        San Francisco, CA 94115
                    </address>
                    <div className='event-note'>
                        There will be a champagne toast to the newlyweds and light snacks
                        after the ceremony, in the beautiful church gardens
                    </div>
                    <a className='directions' href='https://goo.gl/maps/Us9Se2LFcKUN9rSZA' target='_blank' rel='noopener noreferrer'>Directions</a>
                </div>
            </div>
            <div className='schedule-section'>
                <div className='event-title'>
                    <span className='event-name'>Family Dinner</span>
                    <span className='event-date'>Saturday, September 14th</span>
                    <span className='event-time'>5:00pm - 7:30pm</span>
                </div>
                <div className='event-details'>
                    <address className='event-address'>
                        <strong>Isa</strong><br />
                        3324 Steiner St<br />
                        San Francisco, CA 94123
                    </address>
                    <div className='event-note'>
                        (Note: Dinner at Isa is for family members only. Feel free to join us at Detour later on!)
                    </div>
                    <a className='directions' href='https://goo.gl/maps/UjCSYX4Nv54jLqzm6' target='_blank' rel='noopener noreferrer'>Directions</a>
                </div>
            </div>
            <div className='schedule-section'>
                <div className='event-title'>
                    <span className='event-name'>Drinks & Newlyweds (and Games)!</span>
                    <span className='event-date'>Saturday, September 14th</span>
                    <span className='event-time'>8:00pm - 11:00pm</span>
                </div>
                <div className='event-details'>
                    <address className='event-address'>
                        <strong>The Detour</strong><br />
                        2200A Market St<br />
                        San Francisco, CA 94114
                    </address>
                    <div className='event-note'>
                        The Detour is 21+ after 5pm
                    </div>
                    <a className='directions' href='https://g.page/thedetoursf?share' target='_blank' rel='noopener noreferrer'>Directions</a>
                </div>
            </div>
        </section>
        <section className='san-francisco bg-fixed'></section>
    </div>
)

export default Schedule;