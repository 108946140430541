import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import RSVP from './components/RSVP';
import Header from './components/Header';
import Footer from './components/Footer';
import Schedule from './components/Schedule';
import Home from './components/Home';
import Registry from './components/Registry';

function App() {
  return (
    <Router>
      <Header />
      <Route exact path="/" component={Home} />
      <Route path="/schedule" component={Schedule} />
      <Route path="/rsvp" component={RSVP} />
      <Route path="/registry/return" component={Registry} />
      <Route path="/registry/cancel" component={Registry} />
      <Route path="/registry" component={Registry} />
      <Footer />
    </Router>
  );
}

export default App;
