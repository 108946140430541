import React from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

const Footer = () => (
    <footer>
        <div className='footer-nav'>
            <h4>Jenn & Paul</h4>
            <ul>
                <li><Link to="/" onClick={() => window.scrollTo(0,0)}>Home</Link></li>
                <li><Link to="/schedule" onClick={() => window.scrollTo(0,0)}>Schedule</Link></li>
                <li><Link to="/rsvp" onClick={() => window.scrollTo(0,0)}>RSVP</Link></li>
                <li><Link to="/registry" onClick={() => window.scrollTo(0,0)}>Registry</Link></li>
            </ul>
        </div>
        <div className='colophon'>
            © 2019 jennandpaul.com, all rights reserved.
        </div>
    </footer>
);

export default Footer;