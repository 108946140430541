import React from 'react';
import { Link } from 'react-router-dom';
import './home.scss';

const Home = () => (
    <div className='view'>
        <section className='faces bg-fixed'>
            <h2 className='image-heading'>We got married!</h2>
        </section>
        <section className='about-us'>
            <div className='content'>
                <div className='names'>
                    <span className='jenn-paul'>
                        Jenn Spencer<br/>Paul Thrasher
                    </span>
                    <span className='ampersand'>&</span>
                </div>
            </div>
        </section>
        <section className='when-where'>
            <div className='content'>
                <div className='location-date'>
                    <span className='city'>San Francisco, CA</span>
                    <span className='date'>September 14th, 2019</span>
                </div>
            </div>
        </section>
        <section className='church bg-fixed'>
            <h2 className='image-heading'>Swedenborgian Church of San Francisco</h2>
        </section>
        <section className='details'>
            <div className='content'>
                <h3>We totally got married!</h3> 

                <p>Thank you to all of our friends and family that helped make our day awesome and unforgettable!</p>

                <p>Feel free to subscribe to our <Link to="/rsvp" onClick={() => window.scrollTo(0,0)}>newsletter</Link> if you'd like to know when we're headed to a town near you to continue the fun.</p>

                {/* <p>The ceremony is taking place at the Swedenborgian Church in the lovely Pacific Heights neighborhood
                in San Francisco, California. There is some street parking available around the church itself, but
                we recommend carpooling and/or heading over in a Lyft/Uber.</p>
                
                <p>Anyone who is in town is invited to join us for dinner and drinks after the rehearsal 
                Friday night. There is no formal reception, but we're having a family-only dinner after the ceremony, 
                so our newly united families can get to know each other. Friends (and family!) are welcome to join us for drinks
                and late night snacks that evening!*</p>

                <p>Check out the <Link to="/schedule" onClick={() => window.scrollTo(0,0)}>schedule</Link> for more
                details. There is also no dress code for the ceremony, so come as fancy as you wanna!</p> 

                <p>We understand that San Francisco isn't the easiest place to visit, and we're also having a
                relatively small ceremony, so we're heading to a few different cities to celebrate with our friends
                and family afterward. If you're not able to make it, there's a good chance we'll be coming to a city near
                you. Just make sure to check the "Party later!" box when you <Link to="/rsvp" onClick={() => window.scrollTo(0,0)}>RSVP</Link> if you'd
                like to be kept up to date about when/where we'll be!</p>

                <p><em>* - Sadly, there are no open bars or catered meals for us this weekend, so please bring your pocketbook.</em></p> */}
            </div>
        </section>
    </div>
)

export default Home;