import React, { Fragment } from 'react';

const Step1 = ({ 
  emailAddress,
  handleInputChange,
  currentStep,
  partySize,
  furtherNotice,
  emailAddressError,
  message,
}) => {

  if (currentStep !== 1) {
    return null
  }

  return (
    <Fragment>
      <div className="field">
        <label className="label">Email address</label>
          <div className="control">
          <input
            type="email"
            className={ emailAddressError ? 'is-danger input' : 'input'}
            name="emailAddress"
            placeholder="ex. email@example.com"
            required
            value={emailAddress}
            onChange={handleInputChange}
          />
        </div>
        { emailAddressError && <p className='help is-danger'><strong>{ emailAddressError }</strong></p> }
        <p className='help'>Of course, we promise we won't sell your email address, 
          unless it's for lots of money and we'd totally split it with you.</p>
      </div>
      
      <div className="field">
        <label className="checkbox">
          <input
            type="checkbox"
            className="checkbox"
            name="furtherNotice"
            value={furtherNotice}
            onChange={handleInputChange}
          />{' '}
          We'd/I'd like to party with you later on! Keep me filled in about where you'll be.
        </label>
        <p className="help">
          We'll send out an email in the near future with our projected cities and dates, and the option to opt out of or 
          choose a specific city for updates.
        </p>
      </div>
      <div className="field">
        <label className="label">Leave us a message!</label>
        <div className="control">
          <textarea
            name="message"
            className="textarea"
            placeholder="Yeessss?"
            value={message}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </Fragment>
)}

export default Step1;